import { Flex } from '@stars-ecom/shared-atoms-ui'
import { DataLayerUtils, SimplePageLayout } from '@stars-ecom/shared-organisms-ui'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { lengthLimiter } from '../utils/metaTitleFormatter'
import { simplifyGQLResult } from '../utils/strapi'

const SimplePage = (props) => {
  const { data } = props
  const simplePage = simplifyGQLResult(get(data, 'strapi.simplePage'))

  useEffect(() => {
    DataLayerUtils.updateDataLayer({ pageType: simplePage?.title })
  }, [])

  return (
    <Flex width="100%" backgroundColor="#f6f3f1" justify="center">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${lengthLimiter(simplePage?.metaTitle)}`}</title>
        <meta name="description" content={simplePage?.metaDescription} />
        {simplePage?.noIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      <SimplePageLayout simplePage={simplePage} />
    </Flex>
  )
}

export const query = graphql`
  query simplePage($idSimplePage: ID) {
    strapi {
      simplePage(id: $idSimplePage) {
        data {
          id
          attributes {
            title
            slug
            content
            description
            noIndex
            metaTitle
            metaDescription
          }
        }
      }
    }
  }
`
SimplePage.pageType = 'simplePage'

export default SimplePage
